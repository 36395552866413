import { useState, useEffect, useMemo } from "react";
import { useContext } from "react";
import { MyContext } from "../../context/ContextApi";
import useSWR, { mutate } from "swr";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import { Col, Radio, Row, Typography } from "antd";
import { PoweroffOutlined, PrinterOutlined } from "@ant-design/icons";
import { dateCalculation } from "../../Services/TextChangeGlobal";
import { Button, Flex, Table, Modal } from "antd";
import dayjs from "dayjs";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import SabpaisaPaymentGateway from "../../sabPaisa/SabpaisaPaymentGateway";

import {
  getNewData,
  getDataById,
  deleteData,
  addDataTigget,
  updateData,
  addData,
  PayOnline,
  sendRequest,
  FeesDetailsUrl as feesKey,
} from "../../Api/api";

import { Card } from "antd";
import { ConfigFile } from "../../Services/ConfigFile";

function Dashboard() {
  const { generalSetting } = useContext(MyContext);
  console.log("asssssssdasdasdasd", generalSetting);
  const [open, setOpen] = useState(false);

  // const id = "235";

  // const classid = "68";
  // const session_id = "15";

  const [img, setImage] = useState();
  //const { TextArea } = Input;
  const path = ConfigFile.BaseUrl;
  const naviagate = useNavigate();

  const [totalAmount, setTotalAmount] = useState(1);

  const Userdata = JSON.parse(localStorage.getItem("Userdata"));

  console.log("dssdsadasdasdasd",Userdata);
  ///--------------------------- sab Paisa---------------------//

  // const [isOpen, setIsOpen] = useState(false);
  // const [clientCode, setClientCode] = useState("TM001");
  // const [transUserName, setTransUserName] = useState("spuser_2013");
  // const [transUserPassword, setTransUserPassword] = useState("RIADA_SP336");
  // const [authkey, setAuthkey] = useState("kaY9AIhuJZNvKGp2");
  // const [authiv, setAuthiv] = useState("YN2v8qQcU3rGfA1y");

//----------------------live --------------------///
  const [isOpen, setIsOpen] = useState(false);
  const [clientCode, setClientCode] = useState("TATAD");
  const [transUserName, setTransUserName] = useState("bhabesh.jha_7317");
  const [transUserPassword, setTransUserPassword] = useState("TATAD_SP7317");
  const [authkey, setAuthkey] = useState("FqpvCLfZcb99cVkh");
  const [authiv, setAuthiv] = useState("WwTGgVMlyGYe63La");

  const [clientTxnId, setclientTxnId] = useState("");

 // const [payerAddress, setPayerAddress] = useState();
  const [callbackUrl, setCallbackUrl] = useState(ConfigFile.LocalUrl+"SuccessPage");
  const [data, setSelectedRowValue] = useState([]);
  const [udf1, setudf1] = useState(null);
  const [udf2, setudf2] = useState(null);
  const [udf3, setudf3] = useState(null);

  // const [channelId, setchannelId] = useState(null);
  // const [programId, setprogramId] = useState(null);
  // const [mcc, setmcc] = useState(null);
  // const [amountType, setamountType] = useState(null);
  // const [selectedOption, setSelectedOption] = useState("");

  ////--------------------------- sab paisa-----------------//


  console.log("asdasdasdasd",data);

  const {
    isLoading,
    error,
    data: newData,
  } = useSWR(PayOnline, () =>
    getDataById(
      PayOnline,
       {
        session_id: JSON.parse(localStorage.getItem("Userdata"))?.session_id,
      },
      ),
      {
      onSuccess: (data) => 
        {
          console.log("kjcsdaibsdfbsdfbhsdh",data.data[0]);
          const studentName=data?.data[0]?.firstname +" "+data?.data[0]?.lastname;
          console.log("kjcsdaibsdfbsdfbhsdh",studentName);
         // setPayerName(studentName);
         // setPayerEmail(data?.data[0]?.email);
          //setPayerMobile(data?.data[0]?.guardian_phone);
         // setPayerAddress(data?.data[0]?.address);
          setudf1(data?.data[0]?.admission_no);
          setudf2(data?.data[0]?.student_id);
          setudf3(data?.data[0]?.no_emi);
         // setudf3(data?.data[0]?.student_id);

        },
    }
  );


  const logout = () => {
    console.log("asdasdsad", "asdsada");

    localStorage.clear();
    naviagate("/");
  };

  // const [studentData, setStudentData] = useState({
  //   student_id: id,
  //   emi_id: "",
  //   receipt_type: "",
  //   payment_type: "",
  //   amount: 0,
  //   admission_id: "",
  //   emi_discount: 0,
  // });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
 // const [selectedRowValue, setSelectedRowValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settings, setsettings] = useState();

  const [uploadUrl, setUploadUrl] = useState();
  const [print_type, setPrintType] = useState();

  const hasSelected = selectedRowKeys?.length > 0;
  // const hasSelected = selectedRowKeys?.length > 0;

  const enterLoading = () => {
    localStorage.clear();
    naviagate("/");
  };

  

  const viewStatus = (billdate) => {
    var today = dayjs().format("YYYY-MM-DD");
    var nextbill = dayjs(billdate?.next_due_date).format("YYYY-MM-DD");
    const date1 = dayjs(billdate?.next_due_date).diff(today);

    return (
      <>
        {date1 <= 0 ? (
          <>
            <span
              style={{ marginRight: "5px", fontSize: "10px" }}
              className="label label-danger"
            >
              Due
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                marginRight: "5px",
                fontSize: "10px",
                backgroundColor: "#cd8080",
              }}
              className="label"
            >
              Upcoming
            </span>
          </>
        )}{" "}
      </>
    );
  };

  const viewDetails = async (emi_id, type) => {
    console.log("sssssssssssss", emi_id);
    naviagate(`/FeeReciptPrint?receipt_id=${emi_id}&type=${type}`);
  };

  const submitAllData = () => {
    // setLoading(true);
    // setStudentData((prev) => ({
    //   ...prev,
    //   student_id: id,
    //   emi_id: "",
    //   receipt_type: "Fees_master",
    //   amount: totalAmount,
    //   classid_id: classid,
    //   session_id: session_id,
    // }));
    // setUploadUrl("/fess_managment/FessStatement/collectFessmasterPayment");
    // setOpen(true);
  };

  const AmountFormat = (amount) => {
    // const { generalSetting } = useContext(MyContext);
    return (
      <>
        {newData?.settings[0]?.currency_symbol}
        {Number(amount).toLocaleString("en-IN")}
      </>
    );
    //return {generalSetting?.currency_symbol} amount
  };

  const paid_columns = [
    {
      title: "Recipt",
      dataIndex: "fees_name",
      render: (item, record, index) => (
        <div style={{ fontSize: "11px" }}>
          {record?.id ? record?.fees_name : record?.receipt_no}
          {record.id ? (
            <>{dateCalculation(record.duration, record?.fee_collect_for)}</>
          ) : (
            <></>
          )}
        </div>
      ),
      onCell: (record, index) => ({
        colSpan: record?.id ? 2 : 1,
      }),
    },
    {
      title: "Date",
      key: "date",
      render: (item, record, index) => {
        return (
          <>
            {record.id ? (
              <></>
            ) : (
              <div style={{ fontSize: "11px" }}>
                <div
                  style={{ marginRight: "5px", fontSize: "11px" }}
                  className="label label-success"
                >
                  {record?.duration
                    ? record?.fee_collect_for
                    : record?.receipt_date}
                </div>
              </div>
            )}
          </>
        );
      },
      onCell: (record, index) => ({
        colSpan: record?.id ? 0 : 1,
      }),
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "paid_amount",
      render: (item, record, index) => {
        return (
          <div style={{ fontSize: "11px" }}>
            {record?.id ? (
              <>
                {AmountFormat(record.paid_amount)}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#f30000",
                  }}
                >
                  Fine {AmountFormat(record.late_fine)}{" "}
                </div>
              </>
            ) : (
              <div style={{ flexDirection: "row" }}>
                <div>Total : {AmountFormat(record.total_payable_amount)}</div>
                <div>Discount : {AmountFormat(record.fees_discount)}</div>
                <div>Paid : {AmountFormat(record.total_paid_amount)}</div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "operation",
      render: (item, record, index) => {
        return (
          <div style={{ fontSize: "11px" }}>
            {record?.id ? (
              <></>
            ) : (
              <>
                <span
                  style={{ marginRight: "5px", fontSize: "10px" }}
                  className="label label-success"
                >
                  Paid
                </span>
                {/* <Button
                  style={{ padding: "0px",border:"none" }}
                  onClick={() =>
                    viewDetails(record?.fees_receipt_id, "feesmaster")
                  }
                > */}

                {/* <PrintIcon sx={{ color: "#3892ee" }} fontSize="small" /> */}
                {/* </Button> */}
                <PrinterOutlined
                  onClick={() =>
                    viewDetails(record?.fees_receipt_id, "feesmaster")
                  }
                  style={{ color: "#3892ee", cursor: "pointer", fontSize: 16 }}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newselectedRowKeys, selectedRows) => {
      setSelectedRowValue(selectedRows);
      setSelectedRowKeys(newselectedRowKeys);
    },

    onSelect: (record, selected, selectedRows) => {
      setTotalAmount(0);
      let allAmount = 0;
      if (selected) {
        selectedRows.forEach((data, index) => {
          allAmount += Number(data.due_amount) + Number(data.late_fine);
        });
        setTotalAmount(Number(allAmount));
      } else {
        setTotalAmount(
          Number(totalAmount) -
            Number(Number(record.due_amount) + Number(record.late_fine))
        ); 
      }
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      setTotalAmount(0);
      let allAmount = 0;
      if (selected) {
        selectedRows.forEach((data, index) => {
          allAmount += Number(data.due_amount) + Number(data.late_fine);
        });
        setTotalAmount(allAmount);
      } else {
        setTotalAmount(0);
      }
    },
  };

  const upcoming_columns = [
    {
      title: "Title",
      dataIndex: "fees_name",
      key: "1",
      render: (item, record, index) => {
        return (
          <>
            <div>
              <div>{record?.fees_name}</div>
              <div
                style={{
                  marginRight: "5px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "bold",
                }}
                className=""
              >
                {record?.group_name}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Next Due Date",
      dataIndex: "next_due_date",
      key: "2",
      render: (item, record, index) => {
        return (
          <>
            <div>
              {dateCalculation(record.duration, record?.next_due_date)}
              <div
                style={{
                  marginRight: "5px",
                  fontSize: "12px",
                  color: "#f30000",
                }}
                className=""
              >
                {record?.next_due_date}
              </div>
            </div>
          </>
        );
      },
    },

    {
      title: "Amount",
      key: "amount",
      dataIndex: "due_amount",

      render: (due_amount) => {
        return (
          <div
            style={{
              display: "inline-block",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            {" "}
            {AmountFormat(due_amount)}
          </div>
        );
      },
    },

    {
      title: "Fine",
      dataIndex: "late_fine",
      key: "late_fine",
      render: (late_fine) => {
        return (
          <>
            <div>
              <div
                style={{
                  marginRight: "5px",
                  fontSize: "12px",
                  color: "#f30000",
                  fontWeight: "bold",
                }}
                className=""
              >
                {AmountFormat(late_fine)}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "operation",
      render: (_, record) => {
        return <> {viewStatus(record)}</>;
      },
    },
  ];

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    console.log("sssssssssssssssss", "1111111");
    setIsOpen(true);
    e.preventDefault();
    const btn = document.getElementById("renderSabPaisa");
    btn.click();
    localStorage.setItem("selectedData", JSON.stringify(data));
  };

  return (
    <DashboardLayout>
      {/* {newData?.receipt_info[0] ? ( */}
      {!isLoading ? (
        <div style={{ width: "100%", margin: 0, padding: 0, lineHeight: 1.5 }}>
          <nav
            className="navBar"
            style={{
              // display: "flex",
              // justifyContent:"space-between",
              // // justifyContent:"center",
              // alignItems:"center",
              // flexWrap: "wrap",
              padding: 5,
              paddingTop: 10,
              backgroundColor: newData?.receipt_info[0]?.color_code
                ? newData?.receipt_info[0]?.color_code
                : "#e6eefc",
            }}
          >
            <div
              className="header_div"
              // style={{display:"grid",gridTemplateColumns:"3fr 36fr 1fr"}}
            >
              <div>
                <img
                  className="branchLogo"
                  src={`${ConfigFile.ImageUrl}/branch/logo/${newData?.receipt_info[0]?.fees_receipt_logo}`}
                  style={{
                    // width: "127px",
                    // height: "126px",
                    textAlign: "right",
                  }}
                />
              </div>
              <div
                style={{
                  //  fontSize: "50px",
                  // display:"flex",
                  // alignItems:"center",
                  // marginTop:10,
                  color: "#292929",
                  fontWeight: "bold",
                }}
                className="school_name"
              >
                <div>{newData?.receipt_info[0]?.name}</div>
                <div
                  className="headerDetails"
                  style={{ color: "#000", fontWeight: "500" }}
                >
                  {newData?.receipt_info[0]?.affliated_description}
                </div>
                <div
                  className="headerDetails headerDetails2"
                  style={{ color: "#000", fontWeight: "500" }}
                >
                  {newData?.receipt_info[0]?.address}
                </div>
                <div
                  className="headerDetails"
                  style={{ color: "#000", fontWeight: "500" }}
                >
                  Phone : {newData?.receipt_info[0]?.offical_number}
                </div>
                {/* <div style={{ color: "#000",fontSize:14,fontWeight:"500" }}>
                  Email : {newData?.receipt_info[0]?.email_id}
                </div> */}
                {newData?.receipt_info[0]?.affliated_number && (
                  <div
                    className="headerDetails"
                    style={{ color: "#000", fontWeight: "500" }}
                  >
                    Affiliation Number :{" "}
                    {newData?.receipt_info[0]?.affliated_number}
                  </div>
                )}
              </div>
              {/* </Col> */}
              {/* </Row> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 5,
                  paddingRight: 5,
                  width: "100%",
                }}
              >
                <Flex gap="small" wrap>
                  <Button
                    type="primary"
                    icon={<PoweroffOutlined />}
                    loading={false}
                    onClick={() => enterLoading()}
                  />
                </Flex>
              </div>
            </div>
          </nav>

          <div style={{ backgroundColor: "#fff", padding: 2 }}>
            <h2 style={{ textAlign: "center" }}> Student Information</h2>

            <div>
              <div>
                <div sx={{ border: 1 }}>
                  <div
                  // container
                  >
                    <Row>
                      <Col xs={{ flex: "100%" }}>
                        <div></div>
                        <div>
                          <div>
                            {" "}
                            <Row
                              style={{ border: "1px solid #000", padding: 5 }}
                            >
                              {" "}
                              <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                {" "}
                                Name:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {newData?.data[0]?.firstname}{" "}
                                  {newData?.data[0]?.lastname}
                                </span>
                              </Col>
                              <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                {" "}
                                Fathers Name:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {newData?.data[0]?.father_name}{" "}
                                </span>
                              </Col>
                              <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                {" "}
                                Fathers Phone:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {newData?.data[0]?.guardian_phone}
                                </span>
                              </Col>
                              <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                {" "}
                                Roll No:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {newData?.data[0]?.roll_no}
                                </span>
                              </Col>
                              <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                {" "}
                                Admission No.:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {newData?.data[0]?.admission_no}
                                </span>
                              </Col>
                              {newData?.data[0]?.batch_name ? (
                                <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                  {" "}
                                  Batch Name:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {newData?.data[0]?.batch_name}
                                  </span>
                                </Col>
                              ) : null}
                              {newData?.data[0]?.courses_name ? (
                                <Col xs={{ flex: "100%" }} md={{ flex: "50%" }}>
                                  {" "}
                                  Course Name:{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {newData?.data[0]?.courses_name}
                                  </span>
                                </Col>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col xs={{ flex: "100%" }}>
                        {newData?.emi_data && newData?.emi_data.length ? (
                          <>
                            <div>
                              <h2 style={{ textAlign: "center" }}>
                                Payment Details
                              </h2>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div
                          style={{
                            flex: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {newData?.upcoming_payments.length ? (
                            <>
                              <div
                                className="table-responsive"
                                style={{ flexGrow: 1, border: "1px solid" }}
                              >
                                <form className="xyz" onSubmit={handleSubmit}>
                                  <div id="renderSabPaisa"></div>
                                  <div
                                    style={{
                                      width: "100%",
                                      // border: "1px solid",
                                      borderBottom: "1px solid",
                                      fontSize: "14px",
                                      color: "#000",
                                      fontWeight: "bold",
                                      padding: "5px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    Upcoming Fees
                                    <Button
                                      style={{ float: "right" }}
                                      // type="primary"
                                      type="primary"
                                      htmlType="submit"
                                      // onClick={(e) => submitAllData()}
                                      //</div>onClick={start}
                                      disabled={!hasSelected}
                                      loading={loading}
                                    >
                                      {`Pay Now `} {AmountFormat(totalAmount)}
                                    </Button>
                                    {/* :null} */}
                                  </div>
                                  <SabpaisaPaymentGateway
                                    clientCode={clientCode}
                                    transUserName={transUserName}
                                    transUserPassword={transUserPassword}
                                    authkey={authkey}
                                    authiv={authiv}
                                    payerName={newData?.data[0]?.firstname+" "+newData?.data[0]?.lastname}
                                    payerEmail={newData?.data[0]?.email?newData?.data[0]?.email:"noreplay@tatadavjoda.com"}
                                    payerMobile={newData?.data[0]?.guardian_phone}
                                    clientTxnId={clientTxnId}
                                    amount={totalAmount}
                                    payerAddress={newData?.data[0]?.address}
                                    callbackUrl={callbackUrl}
                                    isOpen={isOpen}
                                    udf1={udf1}
                                    udf2={udf2}
                                    udf3={udf3}
                                   // data={data}
                                  />
                                </form>
                                <Table
                                  columns={upcoming_columns}
                                  pagination={false}
                                  rowSelection={rowSelection}
                                  // rowSelection={{ ...rowSelection, checkStrictly }}
                                  //  dataSource={datarow}
                                  dataSource={newData?.upcoming_payments}
                                  size="small"
                                  rowKey="fees_index_id"
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {newData?.Paid_fees.length ? (
                            <div
                              className="table-responsive"
                              style={{ flexGrow: 1, border: "1px solid" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  // border: "1px solid",
                                  borderBottom: "1px solid",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "bold",
                                  padding: "13px",
                                }}
                              >
                                <span>Paid Fees</span>
                              </div>
                              <Table
                                columns={paid_columns}
                                pagination={false}
                                //  rowSelection={{ ...rowSelection, checkStrictly }}
                                dataSource={newData?.Paid_fees}
                                size="small"
                                rowKey="fees_receipt_id"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {newData?.emi_data && newData?.emi_data.length ? (
                          <></>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isLoading ? null : (
        <div>No Data Found</div>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
