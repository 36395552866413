import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
function LoginRoute({children }) {
  //const navigate = useNavigate();
  const userData = localStorage.getItem("Userdata");


  console.log("UserdataUserdata","dassdadasd",userData);
  const exists = userData !== null;

  console.log("UserdataUserdata","dassdadasd",exists);

  if(exists)
  {

  ///let jsonString = JSON.parse(localStorage.getItem("Userdata"));

  if (localStorage.getItem("isAuthenticated")) {
    // return  <Navigate to="/dashboard" replace />;
    //console.log("localStorage--------1111",jsonString?.branch_general_settings)

      return <Navigate to="/dashboard" replace />;
  }
  else{
    return <Navigate to="/" replace />;
  }
  }


  return children;
}
export default LoginRoute;
