// const BASE_URL="http://192.168.0.11/"
//const BASE_URL="http://192.168.0.8/"
//const BASE_URL_IMG="http://192.168.0.8/" 
 const BASE_URL="https://payment.tatadavjoda.com/"


  const BASE_URL_IMG="https://liveerp.ubkinfotech.com/"

export const ConfigFile = {


  // BaseUrl: `${BASE_URL}bansal_group_web/api`,
  // IconUrl: `${BASE_URL}bansal_group_web/icons`,
  // ImageUrl: `${BASE_URL}bansal_group_web/uploads`,
  // LocalUrl: "http://localhost:3000/",

 

  BaseUrl: `${BASE_URL}webservice/api`,
  IconUrl: `${BASE_URL}webservice/icons`,
  ImageUrl: `${BASE_URL_IMG}webservice/uploads`,
  LocalUrl: BASE_URL,

};
