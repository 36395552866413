import "./App.css";
import { PaytmButton } from "./paytm-button/paytmButton";
import { Routes, Route, Navigate, useLocation, useNavigate, NavLink } from "react-router-dom";
import { MyContextProvider } from "./context/ContextApi";
import { Layout, Menu, Button } from "antd";
//import { useState, useEffect } from "react";
import ProtectedRoute from "./layouts/authentication/ProtectedRoute";
import LoginRoute from "./layouts/authentication/LoginRoute";
import SignIn from "./layouts/authentication/sign-in";
import Dashboard from "./layouts/dashboard";
import FeeReciptPrint from "./layouts/print/FeeReciptPrint";
import SuccessPage from "./layouts/Success/Success";

const { Header, Content, Footer, Sider } = Layout;

function App() {
  console.log("sdddddddddddd","app.........");
  return (
    // <div>
    //  <PaytmButton />
    // </div>

    // <Routes>
    //   <Route index element={<SignIn />} />
    //  </Routes>

    <MyContextProvider>
      <Layout
        className="site-layout"
        style={{ background: "#EFEFF1", position: "relative" }}
      >
        {/* {controller.layout != "ubkRoure" && (
          <DashboardNavbar
            parentCallback={handleCallback}
            collapsed={collapsed}
          ></DashboardNavbar>
        )} */}
        <Content
          style={{
            overflow: "initial",
            flex: 1,
            //minHeight: getWindowHeight() - 100,
          }}
        >
          <Routes>
            <Route
              exact
              path="/"
              element={
                 <LoginRoute>
                  <SignIn />
                  {/* <SuccessPage /> */}
                </LoginRoute>
              }
            />

          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/SuccessPage" element={<ProtectedRoute><SuccessPage /></ProtectedRoute>} />
          <Route path="/FeeReciptPrint" element={<ProtectedRoute><FeeReciptPrint /></ProtectedRoute>} />
          </Routes>
        </Content>
      </Layout>
    </MyContextProvider>
  );
}

export default App;
