import { useContext } from "react";
import { MyContext } from "../context/ContextApi";

import { Divider, Space, Tag } from 'antd';
import dayjs from "dayjs";

const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

const zero = "Zero";
const arab = "Arab";
const crore = "Crore";
const lakh = "Lakh";
const thousand = "Thousand";
const hundred = "Hundred";
const currency = "Rupees";
const paisa = "Paisa";
const only = "Only";

  export const getStatusText = (
    state,
  ) => {
    switch (state) {
      case 'paid':
        return <div style={{background:'#3cb371',color:'white'}}>PAID</div>;
      case 'due':
        return  <div style={{background:'red',color:'white'}}>DUE</div>;
      case 'partially_paid':
        return  <div style={{background:'#ff8d00',color:'white'}}>Partially Paid</div>;
      case 'Present':
        return <Tag color="success">Present</Tag>;
      case 'Absent':
        return <Tag color="error">Absent</Tag>;
      case 'NO Attendance':
        return <Tag color="error">No Attendance</Tag>;
      case 'one_time':
        return 'ONETIME';
      case 'monthly':
        return 'MONTHLY';
      case 'half_yearly':
        return 'HALF YEARLY';
      case 'quarterly':
        return 'QUARTERLY';
   
      default:
        return '';
    }
  };
  
  export const UpperCaseOne = (
    text,
  ) => {
    return text[0].toUpperCase() +
    text.slice(1)
  };

  export const AmountFormat = (
    amount,
  ) => {
    const { generalSetting } = useContext(MyContext);
    return <>{generalSetting?.currency_symbol}{Number(amount).toLocaleString('en-IN')}</>
    //return {generalSetting?.currency_symbol} amount
  };

  export const dateCalculation = (duration, billdate) => {
    var date;
    switch (duration) {
      case "one_time":
        date = <>{dayjs(billdate).format('MMM YY')}</>;
        break;
      case "half_yearly":
        date = <>
          {dayjs(billdate).format('MMM YY')} -
          {dayjs(billdate).month(Number(dayjs(billdate).format('M')) + 4).format('MMM YY')}
        </>;
        break;
      case "quarterly":
        date = <>
          {dayjs(billdate).format('MMM YY')} ,
          {dayjs(billdate).month(dayjs(billdate).format('M')).format('MMM YY')} ,
          {dayjs(billdate).month(Number(dayjs(billdate).format('M')) + 1).format('MMM YY')}
        </>;
        break;
      default:
        date = <>{dayjs(billdate).format('MMM YY')}</>;
    }
    return (
      <div style={{ fontWeight: "bold", fontSize: "12px" }}>({date}) </div>
    )
  }


  export const convertNumberToWords=(amount) => {
    if (amount === 0) return `${zero} ${currency} ${only}`;
    
    function convert(num) {
      let parts = [];
      if (num >= 1e9) {
        parts.push(`${convert(Math.floor(num / 1e9))} ${arab}`);
        num %= 1e9;
      }
      if (num >= 1e7) {
        parts.push(`${convert(Math.floor(num / 1e7))} ${crore}`);
        num %= 1e7;
      }
      if (num >= 1e5) {
        parts.push(`${convert(Math.floor(num / 1e5))} ${lakh}`);
        num %= 1e5;
      }
      if (num >= 1000) {
        parts.push(`${convert(Math.floor(num / 1000))} ${thousand}`);
        num %= 1000;
      }
      if (num >= 100) {
        parts.push(`${convert(Math.floor(num / 100))} ${hundred}`);
        num %= 100;
      }
      if (num >= 20) {
        parts.push(`${tens[Math.floor(num / 10)]}`);
        if (num % 10 > 0) parts.push(units[num % 10]);
      } else if (num >= 10) {
        parts.push(`${teens[num - 10]}`);
      } else if (num > 0) {
        parts.push(`${units[num]}`);
      }
      return parts.join(" ");
    }
  
    let integerPart = Math.floor(amount);
    let wholeWordPart = convert(integerPart);
    let result = wholeWordPart ? `${wholeWordPart} ${currency}` : '';
  
    let decimalPart = Math.round((amount - integerPart) * 100);
    if (decimalPart > 0) {
      if (wholeWordPart) {
        result += " and ";
      }
      result += `${convert(decimalPart)} ${paisa}`;
    }
  
    return `${result} ${only}`;
  }