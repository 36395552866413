import axios from "axios";
import { ConfigFile } from "../Services/ConfigFile";

export const PayOnline = "/student/PayOnline";
 export const payment_ReciptDetailsUrl="/fess_managment/FessStatement/getReciptDetailsFeesMaster";
 export const payment_reciptUrl="/fess_managment/FessStatement/payment_recipt";
 export const store_ReciptDetailsUrl="/fess_managment/FessStatement/getReciptDetailsStore";
export const collectFessmasterPayment="/student/PayOnline/collectFessmasterPayment";

// const { branch_id } = jsonString;

const delay = () => new Promise((res) => setTimeout(() => res(), 800));

const baseUrl = axios.create({
  baseURL: ConfigFile.BaseUrl,
});

 const token = localStorage.getItem("authToken");

export const getNewData = async (url) => {
  const token = localStorage.getItem("authToken");
  const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  console.log("__________getNewData_____", url);
  const prm=url.includes("?")?"&":"?";
  const session=!url.includes("session_id")?`session_id=${generalSettings?.session_id}`:"";
  const response = await baseUrl.get(`${url}${prm}${session}`, {
    headers: {
      Authorization: token,
      'Accept': '*/*'
    },
  });
  return response?.data;
};

export const addData = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("authToken");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  console.log("ddddfdfdfd", response.data);
  if (response.data?.Status == "Success") {
    return response?.data?.data;
  } else {
    throw new Error(response.data?.msg);
  }
};
export const addDataReturn = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("authToken");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  console.log("ddddfdfdfd", response.data);
  if (response.data?.Status == "Success") {
    return true;
  } else {
    throw new Error(response.data?.msg);
  }
};

export const addDataTigget = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("authToken");

  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
 
    return response?.data;
 
};

export const addDataTiggetwithOutArg = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("authToken");
  const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  const newdata=data?.arg
  const response = await baseUrl.post(url, newdata, {
    headers: {
      Authorization: token,
    },
  });
 
    return response?.data;
 
};


export const addDataTiggetGET = async (url, data) => {
  const newdata=data?.arg
  const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  const prm=url.includes("?")?"&":"?";
  const response = await baseUrl.get(url+prm+"session_id="+generalSettings?.session_id, {

   params:newdata ,
    headers: {
      Authorization: token,
    },
  });
 
    return response?.data;
 
};

export const addDataTigger = async (url, data) => {
  // await delay()
  const token = localStorage.getItem("authToken");
  const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  const newdata=data?.arg
  const prm=url.includes("?")?"&":"?";
  const response  = await baseUrl.get(url+prm+"session_id="+generalSettings?.session_id, {
  params:newdata ,
    headers: {
      Authorization: token,
    },
  });
  return response?.data;
};

export const sendRequest = async (url,  data ) => {

 // console.log("eeeeeeeeeeeeeeeee", JSON.stringify(arg))
  // return false;
  const token = localStorage.getItem("authToken");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, JSON.stringify(data.arg), {
    headers: {
      Authorization: token,
    },
  });
 
  if (response.data?.Status == "Success") {
    return response?.data?.data;
  } else {
    throw new Error(response.data?.msg);
  }
}


export const updateData = async (url, data) => {
  await delay();
  const token = localStorage.getItem("authToken");
  // const generalSettings = JSON.parse(localStorage.getItem("genrall_settings"));
  // if(Boolean(data?.append)){
  //   data.append("session_id",generalSettings?.session_id);
  // }
  // else{
  //   data.session_id=generalSettings?.session_id;
  // }
  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });

  console.log("aaaaaaaaa", response?.data);
  return response?.data;
  //return response?.data
};

export const getDataById = async (url, data) => {

  const token = localStorage.getItem("authToken");
  console.log("jj..",token);

  const response = await baseUrl.post(url, data, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

export const deleteData = async (url, id) => {
  // await delay()
  const token = localStorage.getItem("authToken");
  const response = await baseUrl.delete(`${url}/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

