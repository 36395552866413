import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ConfigFile } from "../Services/ConfigFile";

const MyContext = createContext();
const MyContextProvider = ({ children }) => {

  const [classes, setClasses] = useState([]);
  const [id, setId] = useState();
  const [globalObject,setGlobalObject]=useState();
  const [generalSetting, setGeneralSetting] = useState();
  const [isSchool, setIsSchool] = useState(true);
  const path = ConfigFile.BaseUrl;

  useEffect(() => {


    console.log('asdasdasdasda',"asdasdasdasd");
    // if(localStorage.getItem("Userdata")?.branch_general_settings==='Incomplete')
    // {
    //   return <Navigate to="/Intriduction" replace />;
    // }

    if (localStorage.getItem("genrall_settings") && localStorage.getItem("genrall_settings")!=="undefined") {
      setGeneralSetting(JSON.parse(localStorage.getItem("genrall_settings")));
    }


  }, [MyContext]);


 

  const obj = { classes, id, isSchool, setId, generalSetting, setGeneralSetting,globalObject,setGlobalObject };
  return <MyContext.Provider value={obj}>{children}</MyContext.Provider>;
};
MyContextProvider.propTypes = {
  children: PropTypes.node,
};
export { MyContext, MyContextProvider };
