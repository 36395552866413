
import PropTypes from "prop-types";
//import Grid from "@mui/material/Grid";
import { Card } from 'antd';
import { Grid } from "antd";

import PageLayout from "../../../components/LayoutContainers/PageLayout";

//import Footer from "layouts/authentication/components/Footer";

function BasicLayout({ image, children }) {

  console.log("asdsadasdasdasdasd",image);
  return (
    <PageLayout>
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      /> */}
      <div
      //hoverable
      style={{
        //  backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
        //     image &&
        //     `${linearGradient(
        //       rgba(gradients.dark.main, 0.6),
        //       rgba(gradients.dark.state, 0.6)
        //     )}, url(${image})`,
         width:"100%", height:"100vh",
         backgroundImage:`url(${image})`,
         backgroundPosition: 'center',
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat'
        }}
        // cover={<img alt="example" src={image} />}
      >
      {children}
    </div>
      {/* <Card px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </Card> */}
      {/* <Footer light /> */}
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
