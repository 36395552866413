import React, { useState, useMemo, useRef } from "react";
// import Grid from "@mui/material/Grid";
// import MDBox from "../../components/MDBox";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "components/Navbars/DashboardNavbar";
// import MDTypography from "components/MDTypography";
// import Card from "@mui/material/Card";
// import MDButton from "components/MDButton";
// import UBKSelect from "components/UBKSelect";
// import Divider from "@mui/material/Divider";
// import Alert from "@mui/material/Alert";
// import UBKDate from "components/UBKDate";
// import { Image } from 'antd';
// import dayjs from "dayjs";

// import toast, { Toaster } from "react-hot-toast";
import useSWR from "swr";
// import { addOptions, deleteTodoOptions } from "../../Api/apiOption";
import { ConfigFile } from "../../Services/ConfigFile";
// import UBKSubmit from "components/UBKSubmit";
// import UBKPaginationTable from "layouts/tables/UBKPaginationTable";
import { useNavigate } from "react-router-dom";
import { exact } from "prop-types";
import {
  getNewData,
  getDataById,
  deleteData,
  updateData,
  addData,
  sendRequest,
  store_ReciptDetailsUrl,
  payment_reciptUrl as reciptKey,
  payment_ReciptDetailsUrl as paymentkey,
} from "../../Api/api";

import {
  // AmountFormat,
  convertNumberToWords,
  dateCalculation,
} from "../../Services/TextChangeGlobal";
import ReactToPrint from "react-to-print";

import "./feeReceipt.css";

//   function findArrayElementByTitle(array, title) {
//     const dattta=array?.paymentInfo;
//     const status=false;
//      dattta.filter((element) => {

//         console.log("aasasasasasas",element.Fees_info);
//         if(element?.Fees_info === title)
//         {
//             return true;
//         }
//         else{
//             return false;
//         }

//     })

//   }

function FeeReciptPrint() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const type = params.get("type");

  //?params.get('emi_id'):params.get('receipt_id');
  const receipt_id = params.get("receipt_id");

  const ImageUrl = ConfigFile.ImageUrl;
  const tableRef = useRef();

  const {
    isLoading: isLoadingSession,
    error: errorSession,
    data: reciptdata,
  } = useSWR(
    type === "emi"
      ? reciptKey + `?admission_id=${receipt_id}`
      : type === "feesmaster"
      ? "/student/PayOnline/getReciptDetailsFeesMaster" +
        `?receipt_id=${receipt_id}`
      : store_ReciptDetailsUrl + `?receipt_id=${receipt_id}`,
    getNewData
  );
  //getReciptDetailsFeesMaster

  console.log("aaaaaaaaaa", reciptdata);

  // const { isLoading, error, data: newData, mutate } = useSWR(reciptKey + "/" + id, () => getDataById(reciptKey, { student_id: id }, {
  //     onSuccess: data => data
  //   }
  //   ))

  //number_of_receipt

  const AmountFormat = (amount) => {
    // const { generalSetting } = useContext(MyContext);
    return (
      amount?
      <>
        {reciptdata?.receipt_settings[0]?.currency_symbol}
        {Number(amount).toLocaleString("en-IN")}
      </>
      :null
    );
    //return {generalSetting?.currency_symbol} amount
  };

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById("print").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const additionmap = (map, data) => {
    console.log("map", map);
    let total_value = 0;

    if (data === "emi_amount") {
      map.forEach((element) => {
        total_value = Number(total_value) + Number(element.emi_amount);
      });
    } else if (data === "late_fine") {
      map.forEach((element) => {
        total_value = Number(total_value) + Number(element.late_fine);
      });
    } else if (data === "total") {
      //(Number(ele.emi_amount)+Number(ele?.late_fine? ele.late_fine:0)

      map.forEach((element) => {
        total_value =
          Number(total_value) +
          Number(element.emi_amount) +
          Number(element?.late_fine ? element.late_fine : 0);
      });
    }

    //console.log("sssssssssss",total_value);
    return AmountFormat(total_value);
  };

  

  const printDetails = (count) => {
    let content;
    // let count=reciptdata?.receipt_settings[0]?.number_of_receipt;
    var Object_rows = [];

    console.log("sssssssssssssssssss", count);

    // for (var i = 1; i <= 1; i++) {

    Object_rows.push(
      <div style={{ flex: 1, display: "flex", borderRight: "1px dashed" }}>
        <div
          style={{
            border: "1px solid ",
            margin: "5px",
            padding: "5px",
            flex: 1,
          }}
        >
          <div style={{ flex: 1, display: "flex" }}>
            <div style={{ flex: 0.15 }}>
              <img
                style={{ width: "75px" }}
                src={
                  ImageUrl +
                  "/branch/logo/" +
                  reciptdata?.receipt_info[0]?.fees_receipt_logo
                }
              />
            </div>
            <div style={{ flex: 0.8 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontSize: "25px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "900",
                  padding: 0,
                }}
              >
                <b>{reciptdata?.receipt_info[0]?.name}</b>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <span style={{ textAlign: "center" }}>
                  <b>{reciptdata?.receipt_info[0]?.cin}</b>
                </span>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <b>{reciptdata?.receipt_info[0]?.address}</b>
                </div>
                {/* <div style={{ margin: 0, padding: 0, fontSize: '13px', textAlign: "center" }}>Waghodia-Dabhoi Ring Road Vadodara</div> */}
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "13px",
                    textAlign: "center",
                  }}
                >
                  <b>Phone : {reciptdata?.receipt_info[0]?.offical_number}</b>{" "}
                </div>
                {/* <div style={{ margin: 0, padding: 0, fontSize: '13px', textAlign: "center" }}>Email :  {reciptdata?.receipt_info[0]?.email_id}</div> */}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              background: "#93bef5",

              marginTop: "10px",
              marginBottom: "10px",
              fontWeight: "bold",
              padding: 0,
              height: "30px",
              WebkitPrintColorAdjust: exact,
            }}
          >
            {type == "emi" ? "ADMISSION" : null} RECEIPT
          </div>

          <div style={{ width: "100%" }}>
            <table
              style={{ margin: 0, padding: 0, fontSize: "13px", width: "100%" }}
            >
              <tr>
                <td>
                  <b>
                    {" "}
                    Admission. No. - {reciptdata?.paymentInfo[0]?.admission_no}
                  </b>
                </td>
                <td style={{ fontWeight: "bold", float: "right" }}>
                  Reecipt No. {reciptdata?.paymentInfo[0]?.receipt_no}{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Student Name -</b> {reciptdata?.paymentInfo[0]?.firstname}{" "}
                  {reciptdata?.paymentInfo[0]?.lastname}
                </td>
                <td>
                  <b>Father Name -</b> {reciptdata?.paymentInfo[0]?.father_name}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Class/Standard -</b> {reciptdata?.paymentInfo[0]?.class}
                </td>
                <td>
                  <b>Section :</b> {reciptdata?.paymentInfo[0]?.section}
                </td>
              </tr>
            </table>
          </div>

          <div class="py-2">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                background: "#93bef5",
                backgroundColor: "#93bef5",
                marginTop: "10px",
                marginBottom: "10px",
                fontWeight: "bold",
                padding: 0,
                height: "30px",
              }}
            >
              FEE DETAILS
            </div>

            <div class="table-responsive">
              <table
                class="table align-middle table-nowrap table-centered mb-0"
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "13px",
                  width: "100%",
                  textAlign:"left"
                }}
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Payment</th>
                    <th class="text-end">Amount</th>

                    <th class="text-end">Fine</th>
                    <th class="text-end" style={{textAlign:"right"}}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {reciptdata?.paymentInfo.map((ele, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {ele.Fees_info}{" "}
                        {ele?.fee_collect_for &&
                          dateCalculation(ele?.Fees_info, ele?.fee_collect_for)}
                      </td>
                      <td class="text-end">{AmountFormat(ele?.emi_amount)}</td>
                      <td class="text-end">
                        {ele?.late_fine ? AmountFormat(ele?.late_fine) : null}
                      </td>
                      <td class="text-end" style={{textAlign:"right"}}>
                        {AmountFormat(
                          Number(ele.emi_amount) +
                            Number(ele?.late_fine ? ele?.late_fine : 0)
                        )}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td>
                      <b>Date:</b> {reciptdata?.paymentInfo[0]?.receipt_date}
                    </td>
                    <th scope="row" colspan="3" class="text-end" style={{textAlign:"right"}}>
                      Total :
                    </th>

                    <td class="text-end" style={{textAlign:"right"}}>
                      <div class="m-0 fw-semibold">
                        <b>
                          {" "}
                          {AmountFormat(
                            Number(
                              reciptdata?.paymentInfo[0]?.total_payable_amount
                            )
                          )}
                        </b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-0" >
                      <b>Payment By :</b>{" "}
                      {reciptdata?.paymentInfo[0]?.payment_mode}
                    </td>
                    <th scope="row" colspan="3" class="border-0 text-end" style={{textAlign:"right"}}>
                      Discount :{" "}
                    </th>
                    <td class="border-0 text-end" style={{textAlign:"right"}}>
                      {" "}
                      {AmountFormat(reciptdata?.paymentInfo[0]?.emi_discount)}
                    </td>
                  </tr>

                  {/* 
                                   <tr>
                                    <td><b>In Word : </b>{convertNumberToWords(reciptdata?.paymentInfo[index]?.receipt_amount)}</td>
                                   <th scope="row" colspan="3" class="text-end">Received Amount :</th>
                                    <td class="text-end"><div class="m-0 fw-semibold"><b> {AmountFormat(Number(reciptdata?.paymentInfo[index]?.receipt_amount))}</b></div></td>
                                    
                                  </tr> */}

                  {reciptdata?.paymentHistory.map((ele, index) => (
                    <tr>
                      <td>
                        <b>In Word : </b>
                        {convertNumberToWords(ele?.receipt_amount)}
                      </td>
                      <th scope="row" colspan="3" class="text-end" style={{textAlign:"right"}}>
                        <div>
                          <div>Received Amount :</div>
                          <div>({ele?.receipt_date})</div>
                        </div>
                      </th>
                      <td class="text-end" style={{textAlign:"right"}}>
                        <div class="m-0 fw-semibold">
                          <b> {AmountFormat(Number(ele?.receipt_amount))}</b>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {reciptdata?.paymentInfo[0]?.due_amount != "0.00" ? (
                    <>
                      <tr>
                        <td></td>
                        <th scope="row" colspan="3" class="text-end">
                          Due Amount :
                        </th>
                        <td class="text-end">
                          <div class="m-0 fw-semibold">
                            <b>
                              {" "}
                              {AmountFormat(
                                Number(reciptdata?.paymentInfo[0]?.due_amount)
                              )}
                            </b>{" "}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <th scope="row" colspan="3" class="text-end">
                          Due Date :
                        </th>
                        <td class="text-end">
                          <div class="m-0 fw-semibold">
                            {" "}
                            {
                              reciptdata?.paymentInfo[0]
                                ?.due_amount_payment_date
                            }
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : null}

                  <tr>
                    <td class="border-0">
                      <b>Remark :</b> {reciptdata?.receipt_info[0]?.note}
                    </td>
                    <th scope="row" colspan="3" class="border-0 text-end"></th>
                    <td class="border-0 text-end">
                      <div class="m-0 fw-semibold"></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="4">
                      <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ width: "60%" }}>
                          {" "}
                          {reciptdata?.receipt_settings[0]?.receipt_notes}
                        </div>
                        <div>
                          {/* <div style={{ width: "40%", textAlign:"center",fontWeight: "bold",}}>Sign </div>
                                                <div style={{color:"#000",fontSize:9}}>**This is a computer generated statement and does not require a signature**</div> */}
                        </div>
                      </div>
                      <div>
                        **This is a computer generated statement and does not
                        require a signature**
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );

    // }

    return Object_rows;
  };

  return (
    <DashboardLayout>
      <div
        // pt={2} pb={1}
        style={{ paddingTop: 24, paddingBottom: 12 }}
      >
        {!isLoadingSession ? (
          // <Grid container spacing={6}>
          //     <Grid item xs={12}>
          <div>
            {reciptdata?.receipt_settings[0]?.number_of_receipt ? (
              <div class="container" style={{display:"flex",justifyContent:"center"}}>
                <div className="row page">
                  <div
                    class="col-lg-12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      class="card "
                      id="print"
                      ref={tableRef}
                      style={{ display: "flex", justifyContent: "center",width:"100%" }}
                    >
                      <div style={{ width: "100%", display: "flex" }}>
                        {printDetails(
                          reciptdata?.receipt_settings[0]?.number_of_receipt
                        )}
                        <div class="d-print-none mt-4"></div>
                      </div>
                    </div>

                    <ReactToPrint
                      trigger={() => (
                        // <IconButton>
                        //   <LocalPrintshopOutlinedIcon fontSize="small" style={{ color: "#5080f8" }} />
                        // </IconButton>
                        <div className="d-print-none mt-4">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <a className="btn btn-success me-1">Print</a>
                          </div>
                        </div>
                      )}
                      content={() => tableRef.current}
                    />

                    {/* <div class="float-end">
                      <a onClick={Print} class="btn btn-success me-1">
                        Print
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            ) : (
              <h3 style={{ textAlign: "center", width: "100%" }}>
                Please complete General Settings
              </h3>
            )}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default FeeReciptPrint;
