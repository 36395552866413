import React from "react";
import { useNavigate, Navigate } from "react-router-dom";

function ProtectedRoute({ isSignedIn, children }) {
  const navigate = useNavigate();

  if (!localStorage.getItem("isAuthenticated")) {
 
    return <Navigate to="/" replace />;
  }
  return children;
}
export default ProtectedRoute;
