import React, { useContext, useState } from "react";
// react-router-dom components

import { useNavigate, Navigate } from "react-router-dom";

import { Grid } from "antd";
import { Typography } from "antd";
//import { Input,Button } from 'antd';

import { Button, Checkbox, Form, Input } from "antd";
import { Card, Col, Row } from "antd";
import { MyContext } from "../../context/ContextApi";

import BasicLayout from "./components/BasicLayout";

import bgImage from "../../assets/images/loginback.jpg";
import lofincard from "../../assets/images/logincard.png";

import { ConfigFile } from "../../Services/ConfigFile";
import axios from "axios";
import logo from "../image/tatadavlogo2.png"

function Basic() {
  console.log("ddddddddddddddd", "ssssssssss");
  const navigate = useNavigate();
  const path = ConfigFile.BaseUrl;

  const onFinish = (values) => {
    console.log("Success:", values);

    fetchData(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async (values) => {
    try {
      const response = await axios.post(
        `${path}/student/login/StudentLogin`,
        values
      );
      console.log("Login__", response.data);
      if (response.data.Status == "Success") {
        if (response.data?.authToken || false) {
          const data = response.data?.data;
          console.log("ssssssss1111", data);
          if (data.length != 0) {
            localStorage.setItem("isAuthenticated", true);

            localStorage.setItem("authToken", response.data?.authToken);
            localStorage.setItem(
              "Userdata",
              JSON.stringify(response.data?.data[0])
            );

            navigate("/dashboard");
          } else {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <div className="login-page">
        <div className="login-box">
          <div className="illustration-wrapper">
            <img className="logincardcss" src={lofincard} alt="Login" />
          </div>
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
            <img 
            // className="logincardcss"
            style={{height:"100px",width:"100px"}}
            src={logo} alt="Login" />
            <div style={{textAlign:"center",padding:5,fontWeight:"bold",fontSize:17}}>TATA DAV PUBLIC SCHOOL</div>
            </div>
            {/* <p className="form-title">Welcome back</p> */}
            <p>Login to the Dashboard</p>
            <Form.Item
              name="user_id"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                LOGIN
              </Button>
            </Form.Item>
            <div style={{color:"#000",fontSize:12,display:"flex",justifyContent:"flex-end"}}>
              <div>Powered by <a target="_blank" href="https://www.ubkinfotech.com/" style={{fontWeight:"500",textDecoration:"none"}}>UBK INFOTECH PVT LTD</a></div>
            </div>
          </Form>
        </div>
      </div>
    </BasicLayout>
  );
}

export default Basic;
