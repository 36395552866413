import React, { useState, useEffect } from "react";
import { PaymentInitModal } from "pg-test-project";
import { v4 as uuidv4 } from "uuid";
import uniqid from "uniqid";

import { useNavigate, createSearchParams } from "react-router-dom";

function SabpaisaPaymentGateway(props) {
  const {
    amount,
    data,
    clientCode,
    transUserName,
    transUserPassword,
    authkey,
    authiv,
    payerName,
    payerEmail,
    payerMobile,
    clientTxnId,
    payerAddress,
    callbackUrl,
    amountType,
    udf1,
    udf2,
    udf3,
  } = props;

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [channelId, setchannelId] = useState("");
  const [programId, setprogramId] = useState("");
  const [mcc, setmcc] = useState("");

  useEffect(() => {
    setIsOpen(props?.isOpen);
  }, [props]);

  const search = window.location.search;
  const responseValues = new URLSearchParams(search);
  useEffect(() => {
    
    const idd = responseValues.get("sabpaisaTxnId");
    const status = responseValues.get("sabpaisaTxnId");

    if (idd && status) {
      navigate("/SuccessPage", {
        state: {
          data: search,
        },
      });
    }
  }, [responseValues]);

  return (
    <div>
      {" "}
      {
        //1) Testing with production enironment please pass env="prod"
        // 2)Testing with stageing enironment please pass env="" or env="stage"
        // 3)Default it is going to catch env=""
        <PaymentInitModal
          clientCode={clientCode}
          transUserPassword={transUserPassword}
          transUserName={transUserName}
          isOpen={isOpen}
          clientTxnId={uniqid()}
          authkey={authkey}
          authiv={authiv}
          payerName={payerName}
          payerEmail={payerEmail}
          payerMobile={payerMobile}
          payerAddress={payerAddress}
          amount={amount}
          amountType={amountType}
          udf1={udf1}
          udf2={udf2}
          udf3={udf3}
          onToggle={() => setIsOpen(!isOpen)}
          channelId={channelId}
          programId={programId}
          mcc={mcc}
          label={"Production"}
          env={"prod"}
        />
      }
    </div>
  );
}

export default SabpaisaPaymentGateway;
