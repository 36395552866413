import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
//import MDBox from "components/MDBox";
import { useMaterialUIController, setLayout } from "../../context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {

    setLayout(dispatch, "dashboard");
    //setLayout(dispatch, "Intriduction");
  }, [pathname]);

  return (
    <div
    //   sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
    //     p: 1,
    //     position: "relative",

    //     [breakpoints.up("xl")]: {
    //     //  marginLeft: miniSidenav ? pxToRem(55) : pxToRem(205),  biplab
    //       transition: transitions.create(["margin-left", "margin-right"], {
    //         easing: transitions.easing.easeInOut,
    //         duration: transitions.duration.standard,
    //       }),
    //     },
    //   })}
    >
      {children}
    </div>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
