import { useEffect ,useState} from "react";
import { Card } from "antd";
import {CheckCircleFilled, ExclamationCircleOutlined,PrinterOutlined,HomeOutlined} from "@ant-design/icons";
import { Button, Flex, Table, Modal } from "antd";
import {
    getNewData,
    getDataById,
    deleteData,
    addDataTigget,
    updateData,
    addData,
    sendRequest,
    collectFessmasterPayment as cacheKey,
  } from "../../Api/api";
import "../../App.css";
import { useNavigate, useLocation } from "react-router-dom";
import useSWRMutation from "swr/mutation";

export default function SuccessPage() {
  const location = useLocation();
  const naviagate = useNavigate();
  const resutfromResponse = location?.state?.data;
  const [recipt_id, setReciptid] = useState("");
  const params = new URLSearchParams(resutfromResponse);

  const sabpaisaTxnId = params.get("sabpaisaTxnId");
  const status = params.get("status");
  const paymentMode = params.get("paymentMode");
  const bankName = params.get("bankName");
  const payMobile = params.get("payerMobile");
  const payerEmail = params.get("payerEmail");
  const amount = params.get("amount");
  const paidAmount = params.get("paidAmount");
  const sabpaisaMessage = params.get("sabpaisaMessage");
  const student_id = params.get("udf2");
  const emi_id = params.get("udf3");


  const selectedData = localStorage.getItem("selectedData");
  const Userdata = JSON.parse(localStorage.getItem("Userdata"));


  const { trigger, isMutating, data } = useSWRMutation(
    cacheKey,
    addDataTigget
  );


  useEffect(() => {

    if(status=="SUCCESS" && selectedData!="")
     {

    var object = {};

    object['emi_id'] = emi_id;
  ///  object['student_id'] = student_id;
    object['amount'] = amount;
    object['session_id'] = Userdata?.session_id;
    object['data'] = JSON.parse(selectedData);
     }
  
     submitdata(object);
  }, [status]);

  const submitdata = async (e) => {

    const result = await trigger(e);

    if(result.Status==="Success")
        {
       setReciptid(result.data);
       localStorage.setItem("selectedData", "");
        }

  }

   const viewDetails = async () => {
    console.log("sssssssssssss", recipt_id);
    naviagate(`/FeeReciptPrint?receipt_id=${recipt_id}&type=feesmaster`);
  };

  const backHome=async ()=>{
    naviagate(`/`);
  }


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card className="successCard">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: 3,
          }}
        >
          {status == "SUCCESS" ? (
            <div style={{ marginBottom: 40, textAlign: "center" }}>
              <CheckCircleFilled style={{ color: "#32ba7c", fontSize: 50 }} />
              <div style={{ color: "#32ba7c", fontSize: 20 }}>
                Payment Successful
              </div>
              <div>{sabpaisaMessage}</div>
            </div>
          ) : (
            <div style={{ marginBottom: 40, textAlign: "center" }}>
              <ExclamationCircleOutlined
                style={{ color: "#FF0000", fontSize: 50 }}
              />
              <div style={{ color: "#FF0000", fontSize: 20 }}>
                Payment Failed!
              </div>
              <div>{sabpaisaMessage}</div>
            </div>
          )}

          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Payment type</div>
            <div style={{ fontWeight: "500" }}>{paymentMode}</div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Bank</div>
            <div style={{ fontWeight: "500" }}>{bankName}</div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Mobile</div>
            <div style={{ fontWeight: "500" }}>{payMobile}</div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Email</div>
            <div style={{ fontWeight: "500" }}>{payerEmail}</div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ fontWeight: "bold", color: "#4f4f4f" }}>Amount</div>
            <div style={{ fontWeight: "bold", color: "#4f4f4f" }}>
              ₹{amount}
            </div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ fontWeight: "bold", color: "#4f4f4f" }}>
              Amount Paid
            </div>
            <div style={{ fontWeight: "bold", color: "#4f4f4f" }}>
              ₹{paidAmount}
            </div>
          </div>
          <div
            className="sdetails"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Transaction Id</div>
            <div style={{ fontWeight: "500" }}>{sabpaisaTxnId}</div>
          </div>
          <div
            className="sdetails"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              lineHeight: 2,
              width: "40%",
            }}
          >
          

            <Button
              style={{
                padding: 5,
                backgroundColor: "#0188cc",
                color: "#fff",
                fontWeight: "bold",
                lineHeight: 1.5,
                borderRadius: 5,
              }}
              onClick={(e) => backHome()}
              >
                
                Home
                 <HomeOutlined
                  style={{ color: "#fff", cursor: "pointer", fontSize: 16 }}
                />
              
            </Button>

           
            <Button
              style={{
                padding: 5,
                backgroundColor: "#0188cc",
                color: "#fff",
                fontWeight: "bold",
                lineHeight: 1.5,
                borderRadius: 5,
              }}
              onClick={(e) => viewDetails()}
              >
                
                Receipt
                 <PrinterOutlined
                 
                  style={{ color: "#fff", cursor: "pointer", fontSize: 16 }}
                />
              
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
